<template>
  <div id="app">
    <div v-if="isLoggedIn && !fullscreen">
      <div class="layout-light side-menu overlayScroll">
        <div class="mobile-search"></div>
        <div class="mobile-author-actions"></div>
        <Header />
        <main class="main-content">
          <Aside />
          <router-view />
          <Footer />
        </main>
      </div>
      <Floating />
    </div>
    <div v-else-if="isLoggedIn && fullscreen">
      <router-view />
    </div>
    <div v-else-if="!isLoggedIn">
      <div class="layout-light side-menu">
        <main class="main-content">
          <router-view />
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Aside from '@/components/layouts/Aside.vue'
import Header from '@/components/layouts/Header.vue'
import Footer from '@/components/layouts/Footer.vue'
import Floating from '@/components/layouts/Floating.vue'

export default {
  components: {
    Aside,
    Header,
    Footer,
    Floating
  },
  watch: {
    '$route'(to) {
      this.fullscreen = !!to.meta?.fullscreen;
      document.title = (to.meta.title ? to.meta.title + " - Starsender V3" : 'Starsender V3');
    }
  },
  data() {
    return {
      connection: null,
      isLoggedIn: this.$store.state.auth.isLoggedIn,
      fullscreen: false,
      retryCount: 0,
      maxRetries: 5,
      retryTimeout: null,
      pingInterval: null,
    };
  },
  mounted() {
    if (localStorage.getItem('socket_token')) {
      this.initWS();
    }

    this.$root.$on("logout", () => {
      this.isLoggedIn = false;
    });

    this.$root.$on("login", () => {
      this.isLoggedIn = true;
    });
  },
  beforeDestroy() {
    // Hentikan interval & timeout untuk mencegah memory leak
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
      this.pingInterval = null;
    }
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout);
      this.retryTimeout = null;
    }
    if (this.connection) {
      this.connection.close();
      this.connection = null;
    }
  },
  methods: {
    initWS() {
      if (this.retryCount >= this.maxRetries) {
        console.log("Gagal menyambung kembali setelah 5 kali percobaan.");
        return;
      }

      // Hindari duplikasi koneksi WebSocket
      if (this.connection && this.connection.readyState === WebSocket.OPEN) {
        console.log("WebSocket sudah terhubung, tidak perlu reconnect.");
        return;
      }

      console.log("Menghubungkan ke WebSocket...");
      this.connection = new WebSocket(process.env.VUE_APP_WS_URL);

      this.connection.onopen = () => {
        this.retryCount = 0; // Reset retry count saat sukses
        if (this.retryTimeout) {
          clearTimeout(this.retryTimeout);
          this.retryTimeout = null;
        }

        console.log("WebSocket berhasil terhubung!");

        // Pastikan interval tidak double
        if (this.pingInterval) {
          clearInterval(this.pingInterval);
        }

        // Kirim "ping" hanya saat koneksi berhasil
        this.pingInterval = setInterval(() => {
          if (this.connection && this.connection.readyState === WebSocket.OPEN) {
            this.connection.send("ping");
            console.log("Ping sent");
          }
        }, 14000);
      };

      this.connection.onclose = () => {
        console.log("WebSocket terputus, mencoba reconnect...");

        // Hapus interval saat koneksi terputus
        if (this.pingInterval) {
          clearInterval(this.pingInterval);
          this.pingInterval = null;
        }

        this.connection = null; // Reset koneksi

        if (this.retryCount < this.maxRetries) {
          this.retryCount++;
          console.log(`Percobaan reconnect ke-${this.retryCount}`);
          this.retryTimeout = setTimeout(() => {
            this.initWS();
          }, 2500);
          
        } else {
          console.log("Melebihi batas retry, tidak mencoba lagi.");
        }
      };
    },
  },
};
</script>



<style>
.spinner {
  height: 1rem !important;
  width: 1rem !important;
}

.vm--modal {
  height: auto !important;
  max-height: 95% !important;
  overflow-y: auto !important;
}

@media only screen and (max-device-width: 610px) {
  .vm--modal {
    width: 94% !important;
    margin-left: 3% !important;
    margin-right: 3% !important;
  }
}

hr {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
</style>