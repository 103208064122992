import axios from 'axios';

const state = {
    daily_leads: "",
    daily_leads_rule: "",
};

const getters = {

};

const actions = {
    daily_leads_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('/daily-leads', { params: data })
            .then(response => {
                commit("PUSH_DAILY_LEADS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    daily_leads_member_list({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.get('/daily-leads/' + data.id + '/members', { params: data })
            .then(response => {
                commit("PUSH_DAILY_LEADS_RULE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    daily_leads_rule({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.get('/daily-leads/' + data.id + '/rule', { params: data })
            .then(response => {
                commit("PUSH_DAILY_LEADS_RULE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    daily_leads_create({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.post('/daily-leads', data)
            .then(response => {
                commit("PUSH_DAILY_LEADS_RULE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    daily_leads_rule_create_or_update({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.post('/daily-leads/' + data.id + '/rule', data)
            .then(response => {
                commit("PUSH_DAILY_LEADS_RULE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    daily_leads_export({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.get('/daily-leads/' + data.id + '/export', {params: data})
            .then(response => {
                commit("PUSH_DAILY_LEADS_RULE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    daily_leads_export_to_group({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.get('/daily-leads/' + data.id + '/export-to-group', {params: data})
            .then(response => {
                commit("PUSH_DAILY_LEADS_RULE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    daily_leads_sync({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get('/daily-leads/sync')
            .then(response => {
                commit("PUSH_DAILY_LEADS_RULE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    daily_leads_detail({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.get('/daily-leads/' + data)
            .then(response => {
                commit("PUSH_DAILY_LEADS_DETAIL", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    delete_daily_leads({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.delete('/daily-leads/' + data)
            .then(response => {
                commit("DELETE_DAILY_LEADS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
            })
        })
    }
    
}

const mutations = {
    PUSH_DAILY_LEADS(state, data){
        state.daily_leads = data
    },
    DELETE_DAILY_LEADS(state, data){
        state.daily_leads = data
    },
    PUSH_DAILY_LEADS_RULE(state, data){
        state.daily_leads_rule = data
    },
};


export default {
  state,
  getters,
  actions,
  mutations
};
